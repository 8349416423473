<template>
  <div>
    <div class="p-5 mb-4 bg-white rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Selamat Datang!</h1>
        <p class="col-md-8 fs-4">
          {{
            !anggota.bukti_pembayaran && !isSubmited
              ? statusPending
              : statusProcessing
          }}
        </p>
        <div v-if="!anggota.bukti_pembayaran && !isSubmited">
          <div class="col-md-4">
            <input
              class="form-control"
              type="file"
              @change="changeBuktiPembayaran"
              accept="image/png, image/jpeg"
            />
          </div>
          <div class="col-md-4 mt-2">
            <button
              class="btn btn-primary"
              @click="uploadBuktiPembayaran"
              :disabled="bukti_pembayaran == '' || isSubmiting"
            >
              <i class="bi bi-cloud-upload"></i>
              {{ isSubmiting ? "Mengupload" : "Upload" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 text-white bg-dark rounded-3">
          <h2>Lengkapi Profil</h2>
          <p>
            Sambil menunggu verifikasi bukti pembayaran Anda, ada baiknya untuk
            melengkapi profil Anda saat ini.
          </p>
          <button class="btn btn-outline-light" type="button">
            Edit Profil
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { authenticationService, anggotaService } from "@/services";
export default {
  data() {
    return {
      isLoading: true,
      currentUser: authenticationService.currentUserValue,
      bukti_pembayaran: "",
      isSubmiting: false,
      statusProcessing:
        "Bukti pembayaran sudah diupload dan sedang diproses oleh admin.",
      statusPending:
        "Untuk melanjutkan registrasi keanggotaan Anda, silakan untuk melakukan bukti transfer dibawah ini.",
      anggota: {},
      isSubmited: false,
    };
  },
  methods: {
    changeBuktiPembayaran(e) {
      const selectedFile = e.target.files[0];
      this.bukti_pembayaran = selectedFile;
    },
    async uploadBuktiPembayaran() {
      try {
        this.isSubmiting = true;
        const resp = await anggotaService.uploadBuktiPembayaran(
          this.anggota.id,
          this.bukti_pembayaran
        );
        this.isSubmiting = false;
        this.isSubmited = true;
        this.$toast.success(resp.data.message);
      } catch (error) {
        this.$toast.error(
          `Gagal mengupload bukti pembayaran: ${
            (error.response.data && error.response.data.message) ||
            error.message
          }`
        );
        this.isSubmiting = false;
      }
    },
  },
  async mounted() {
    const id = this.currentUser.id;
    const anggota = await anggotaService.getByUserId(id);
    this.anggota = anggota.data;
  },
};
</script>

<style scoped></style>
